// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-marketing-pages-js": () => import("/codebuild/output/src422482775/src/gildform-gatsby/src/templates/marketing_pages.js" /* webpackChunkName: "component---src-templates-marketing-pages-js" */),
  "component---src-templates-designer-js": () => import("/codebuild/output/src422482775/src/gildform-gatsby/src/templates/designer.js" /* webpackChunkName: "component---src-templates-designer-js" */),
  "component---src-pages-404-js": () => import("/codebuild/output/src422482775/src/gildform-gatsby/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/codebuild/output/src422482775/src/gildform-gatsby/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-brandforge-js": () => import("/codebuild/output/src422482775/src/gildform-gatsby/src/pages/brandforge.js" /* webpackChunkName: "component---src-pages-brandforge-js" */),
  "component---src-pages-create-js": () => import("/codebuild/output/src422482775/src/gildform-gatsby/src/pages/create.js" /* webpackChunkName: "component---src-pages-create-js" */),
  "component---src-pages-dashboard-js": () => import("/codebuild/output/src422482775/src/gildform-gatsby/src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src422482775/src/gildform-gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-loading-js": () => import("/codebuild/output/src422482775/src/gildform-gatsby/src/pages/loading.js" /* webpackChunkName: "component---src-pages-loading-js" */),
  "component---src-pages-login-js": () => import("/codebuild/output/src422482775/src/gildform-gatsby/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-model-js": () => import("/codebuild/output/src422482775/src/gildform-gatsby/src/pages/model.js" /* webpackChunkName: "component---src-pages-model-js" */),
  "component---src-pages-onboarding-js": () => import("/codebuild/output/src422482775/src/gildform-gatsby/src/pages/onboarding.js" /* webpackChunkName: "component---src-pages-onboarding-js" */),
  "component---src-pages-order-confirmation-thankyou-js": () => import("/codebuild/output/src422482775/src/gildform-gatsby/src/pages/order-confirmation/thankyou.js" /* webpackChunkName: "component---src-pages-order-confirmation-thankyou-js" */),
  "component---src-pages-order-index-js": () => import("/codebuild/output/src422482775/src/gildform-gatsby/src/pages/order/index.js" /* webpackChunkName: "component---src-pages-order-index-js" */),
  "component---src-pages-order-order-details-js": () => import("/codebuild/output/src422482775/src/gildform-gatsby/src/pages/order/OrderDetails.js" /* webpackChunkName: "component---src-pages-order-order-details-js" */),
  "component---src-pages-orders-js": () => import("/codebuild/output/src422482775/src/gildform-gatsby/src/pages/orders.js" /* webpackChunkName: "component---src-pages-orders-js" */),
  "component---src-pages-process-js": () => import("/codebuild/output/src422482775/src/gildform-gatsby/src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-production-calculator-js": () => import("/codebuild/output/src422482775/src/gildform-gatsby/src/pages/production-calculator.js" /* webpackChunkName: "component---src-pages-production-calculator-js" */),
  "component---src-pages-profile-js": () => import("/codebuild/output/src422482775/src/gildform-gatsby/src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-user-login-js": () => import("/codebuild/output/src422482775/src/gildform-gatsby/src/pages/userLogin.js" /* webpackChunkName: "component---src-pages-user-login-js" */),
  "component---src-pages-video-js": () => import("/codebuild/output/src422482775/src/gildform-gatsby/src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/codebuild/output/src422482775/src/gildform-gatsby/.cache/data.json")

